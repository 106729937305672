import React from 'react'
import '../styles/course1.css'
import '../styles/course1_2.css' 
import Nav from './Nav'
import Footer from './Footer'
import star from '../img/star.png'
import thumb from '../img/like.png'
import accounting from '../img/accounting.jpg'
import check from '../img/checked.png'
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import '../styles/fancy-example.css';


function HCLv5() {
  return (
    <div>
        <Nav/>
        
        <div className="course-hero">
            <div className='course-head'>
                <h1>Level 5 Diploma in Health and Social Care (RQF) </h1>
                <p>The Level 5 Diploma in Leadership and Management for Adult Care (England) is a competence based qualification, which has been developed with employers who provide adult care. The mandatory content is based on the Skills for Care qualification specifications, which were produced following consultation with the sector.</p>
                <button>Apply Now</button>
            </div>

            <div className="sum-card">
                <h3>Course</h3>
                <p>Gain insight into a topic and learn the fundamentals</p>

                 <div className="hor-gray"></div>       

                <div className="flex-course">
                    <div>
                        <h3>Reg Deadline</h3>
                        <p>Coming Soon</p>
                    </div>  
                    <div className='vert-gray'></div>
                    <div>
                        <h3>Classes Start</h3>
                        <p>Coming Soon</p>
                    </div>
                </div>
                
                <h3>Beginner</h3>
                <p>Recommended Experience</p>

                <h3>Interactive Learning</h3>
                <p>Interact with the course tutors live</p>

                <br />
                <div className="hor-gray"></div>       
                <div className="flex-course2">
                    <p>12 months | 20-30hrs/week</p>
                    
                    <a href="">View course modules</a>
                </div>

            </div>
        </div>

        <div className="about-course">
            <img src="https://images.pexels.com/photos/3756679/pexels-photo-3756679.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            <div>
                <h1>About this course</h1>
                <p>The Level 5 Diploma in Health and Social Care (RQF) is an advanced qualification designed for individuals who aspire to excel in leadership and management roles within the health and social care sector. This diploma builds upon the foundational knowledge gained at lower levels and provides learners with a deeper understanding of complex issues and challenges faced by professionals in this field.</p>
                <p>By completing the Level 5 Diploma in Health and Social Care (RQF), graduates will be equipped with the knowledge, skills, and confidence to assume strategic leadership positions in various health and social care settings. They will possess a comprehensive understanding of the sector's complexities, be adept at managing resources, and be able to influence policy development and implementation. This diploma empowers individuals to make a significant impact on the provision of high-quality care, drive innovation, and contribute to the continuous improvement of health and social care services.</p>
              
            </div>
        </div>

        <div className="learning-outcomes">
            <h2>What you'll learn</h2>
            <div className='grid-2'>
                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Strategic Leadership in Health and Social Care: This concept focuses on developing strategic leadership skills and understanding the broader organizational context within the health and social care sector. Learners will explore strategic planning, change management, and effective decision-making to drive organizational success and improve service delivery.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Policy Development and Implementation: This concept delves into the process of policy development and implementation within health and social care settings. Students will learn how to analyze policy needs, engage stakeholders, and develop policies that align with regulatory requirements and organizational objectives. They will also gain skills in implementing and evaluating policy effectiveness.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Managing Quality and Risk: Quality management and risk assessment are critical aspects of health and social care practice. This concept covers principles and methodologies for managing quality, promoting continuous improvement, and mitigating risks. Learners will explore techniques such as quality audits, risk assessments, and incident management to ensure safe and effective care provision.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Leading and Managing Multidisciplinary Teams: Effective leadership and management of multidisciplinary teams are essential in health and social care settings. This concept focuses on developing skills in team dynamics, collaboration, and conflict resolution. Students will explore strategies for fostering teamwork, motivating employees, and optimizing team performance to achieve organizational goals.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Ethical and Legal Considerations in Advanced Practice: This concept provides an in-depth understanding of ethical and legal frameworks that guide advanced practice in health and social care. Learners will explore complex ethical dilemmas, professional accountability, and legal obligations. They will also examine ethical decision-making models and the application of ethical principles in challenging situations.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Research and Evidence-Based Practice in Health and Social Care: This concept emphasizes the importance of research and evidence-based practice in advancing health and social care services. Students will learn how to critically appraise research literature, apply evidence to inform decision-making, and lead research initiatives. They will also gain skills in disseminating research findings and fostering a culture of evidence-based practice.</p>
                </div>

                
            </div>
        </div>    

        <div className="outline">
            <h2>Course Outline</h2>
            <div className="accordion">
            <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 1-4: Strategic Leadership in Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding strategic leadership concepts and theories</li>
                        <li>Developing strategic plans and objectives</li>
                        <li>Leading change and managing organizational culture</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 5-8: Policy Development and Implementation 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Analyzing policy needs and trends in health and social care</li>
                        <li>Engaging stakeholders in policy development</li>
                        <li>Implementing and evaluating policy effectiveness</li>
                 </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 9-12: Managing Quality and Risk
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Quality management frameworks and methodologies</li>
                        <li>Conducting quality audits and implementing improvement strategies</li>
                        <li>Risk assessment, management, and incident reporting</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 13-16: Leading and Managing Multidisciplinary Teams</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Team dynamics and effective communication</li>
                        <li>Motivating and empowering team members</li>
                        <li>Conflict resolution and performance management</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 17-20: Ethical and Legal Considerations in Advanced Practice</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Ethical frameworks and principles in health and social care</li>
                        <li>Legal obligations and regulations</li>
                        <li>Ethical decision-making and professional accountability</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 21-24: Research and Evidence-Based Practice in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Research methodologies and study design</li>
                        <li>Critical appraisal of research literature</li>
                        <li>Applying evidence to inform practice and improve outcomes</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 25-28: Financial Management and Resource Allocation</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Budgeting and financial planning in health and social care</li>
                        <li>Resource allocation strategies</li>
                        <li>Cost-effectiveness analysis and financial accountability</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 22-24: Supporting Individuals with Specific Needs</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding different needs, such as physical or sensory impairments</li>
                        <li>Adapting support to meet specific needs</li>
                        <li>Promoting independence and enabling individuals to achieve goals</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 33-36: Promoting Person-Centered Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding person-centered care principles</li>
                        <li>Assessing individual needs and preferences</li>
                        <li>Developing and implementing care plans focused on individual well-being</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 37-40:  Health and Social Care Policy and Legislation</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Analyzing key policies and legislation in the sector</li>
                        <li>Understanding the impact of policy on service delivery</li>
                        <li>Ensuring compliance with regulatory requirements</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 41-44: Mental Health and Well-being Leadership</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding mental health conditions and their impact</li>
                        <li>Leading mental health promotion and support initiatives</li>
                        <li>Collaborating with stakeholders in mental health services</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 45-48: Professional Development and Reflective Practice</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Reflective practice and self-assessment</li>
                        <li> Continuous professional development planning</li>
                        <li>Enhancing leadership and management skills</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>                

        </div>   

        <div className="cost">
            <h1>Cost</h1>

            <div className='grid-cost'>
                <div className="white-card">
                    <h4>Monthly</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>30</h2>
                        <p>per month</p>
                    </div>
                    <p>If you choose this plan, you will pay the same amount in tuition every month.</p>
                </div>

                <div className="blue-card">
                    <h4>Upfront</h4>
                    <div className='hor-gray'></div>
                    <div className="blue-price">
                        <p>$</p>
                        <h2>280</h2>
                    </div>
                    <p>If you pay in full before you start, you get 20% off the tuition fee with no additional costs,and nothing to pay when you’re done.</p>
                </div>

                <div className="white-card">
                    <h4>Quartely</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>80</h2>
                        <p>per quarter</p>
                    </div>
                    <p>Pay upfront and save an extra 11% when you choose this payment plan.</p>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default HCLv5
import React from 'react'
import '../styles/coursecard.css'
import course1 from '../img/accounting.jpg'
import { Link } from 'react-router-dom'

function CourseCard({id,name,imgUrl,desc,duration,courseLink}) {

  const scrollToTop = () =>{
      window.scrollTo(0,0)
  }

  return (
    <a href={courseLink} className='buy-link'>
    <div className='card-item'>
        <img src={imgUrl}  alt="" />
        <h4>{name}</h4>
        <p>{desc}</p>
        <div className="gray-line"></div>
        <div className='flex'>
            <p></p>
            <a href={courseLink} style={{textDecoration:"none"}}><button>Learn More</button></a>
        </div>
    </div>
    </a>
  )
}

export default CourseCard

import React from 'react'
import '../styles/courses.css'
import CourseCard from './CourseCard'
import course from '../data/healthcare'
import { Link } from 'react-router-dom'
import Course from '../data/course'

function Courses() {
  
 const courseItem = course.map(item=>{
    return    <CourseCard
                  key={item.courseId}
                  id = {item.courseId}
                  name = {item.courseName}
                  imgUrl = {item.imgUrl}
                  desc = {item.description}
                  duration = {item.duration}
                  courseLink={item.courseLink}
                  />
           
  })
  
  return (
    <div className='courses' id='courses'>
        <h3>Whats New</h3>
        <h1>Explore our Courses</h1>

        <div className="grid-view">
            {courseItem}  
        </div>
    </div>
  )
}

export default Courses
import React from 'react'
import '../styles/hero.css'
import hero_image from '../img/heron.png'
import { HashLink } from 'react-router-hash-link'

function Hero() {
  return (
    <div className='hero' id='home'>
        <div className="hero-text">
            <h1>Embark on a transformative learning journey</h1>
            <p>Discover new passions, gain practical skills, and connect with a vibrant community of learners. Whether you're looking to enhance your career or explore new interests, our expert-led courses and supportive community will empower you to unlock your full potential. </p>
            <HashLink to="/#courses" className='generalLink'><button>Get Started </button></HashLink>
        </div>
        <div className="hero_img">
            <img src={hero_image} alt=""/>
        </div>
        <div className="full-circle"></div>  
        <div className="smaller-circle"></div>
        <div className="dotted-circle"></div>
        <div className="arrow"></div> 
       </div>
  )
}

export default Hero
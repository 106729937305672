import React from 'react'
import Nav from './Nav'
import '../styles/signUp.css'

function SignUp() {
  return (
    <div>
        <Nav/>
        
        <h2 className='sign-head'>Choose portal below</h2>

        <div className="signup">
            <a href="https://portal.weproe.co.uk/login/index.php" className='sign-card1'>
                <h3>Weproe Portal</h3>
                <p>Learning, Notes and Books</p>
            </a>

            <a href="https://login.quals-direct.co.uk" className='sign-card2'>
               <h3>Quals Direct</h3>
                <p>Assignment Submissions</p>
            </a>
            
        </div>

    </div>
  )
}

export default SignUp
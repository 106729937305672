import React from 'react'
import Nav from './Nav'
import Hero from './Hero'
import About from './About'
import Courses from './Courses'
import Contact from './Contact'
import Blog from './Blog'
import NewsLetter from './NewsLetter'
import Footer from './Footer'

function MainPage() {
  return (
    <div>
      <Nav/>
      <Hero/>
      <About/>
      <Courses/>
      <Contact/>
      <Blog/>
      <NewsLetter/>
      <Footer/>
    </div>
  )
}

export default MainPage
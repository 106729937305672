import React from 'react'
import '../styles/adminCourseCard.css'
import courseImg from '../img/course1.jpg'
import { HashLink } from 'react-router-hash-link'

function AdminCourseCard() {
  return (
    <HashLink to={"/addCourse"} style={{textDecoration: "none"}} >
    <div className='adminCourseCard'>
        <img src={courseImg} alt="" />
        <div className='flex1'>
          <p className="adminTitle">Level 5 Diploma in Leadership for Health and Social Care  (RQF) - Adults Pathway</p>
          <p>Level 5 Diploma: Equipping leaders in health and social care with expertise in adult services and compassionate leadership.</p>
        </div>

        <div className='active'>
          <p>active</p>
        </div>

        <p className="adminPrice">£1,199.42</p>

    </div>
    </HashLink> 
  )
}

export default AdminCourseCard
import React from 'react'
import blog1 from '../img/blog1.jpg' 
import '../styles/blogcard.css' 
import arrow from '../img/arrow.png'
import { Link } from 'react-router-dom'

function BlogCard({title,teaser,link,imgUrl}) {

  const scrollToTop = () =>{
    window.scrollTo(0,0)
  }

  return (
    <div className='blog-card'>
        <img src={imgUrl} alt="" />  
        <p className="title"> {title}</p>
        <p className="teaser">{teaser} </p>
        <Link onClick={scrollToTop} className='generalLink' to={`blogs/${link}`}><p className='learn-more'>LEARN MORE</p></Link>
        
        
    </div>
  )
}

export default BlogCard
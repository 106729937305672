import React from 'react'
import '../styles/newsletter.css' 
import send from '../img/paper-plane.png'

function NewsLetter() {
  return (
    <div className='newsletter'>
        <h1>Subscribe to Our Newsletter to Recieve Exclusive Content</h1>

        <div className="news-signup">
          <div className="flexed">
            <input type="email" name="" id="" placeholder='Enter your email'  />
            <img src={send} alt="" />  
          </div>
            <p>Sign up for exclusive content & updates!</p>
            
        </div>

    </div>
  )
}

export default NewsLetter
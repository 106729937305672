import React from 'react'
import '../styles/addCourse.css'

function AddCourse() {
  return (
    <div className='addCourseSec'>
        <h3 className='newCourse'>Add New Course</h3>

        <div className="container">
          <div className="sub-cont1">

              <div className="carded">
                  <p>Title</p>
                  <input type="text" className='courseTitle' />
                  <br /><br />
                  <p>Course Description</p>
                  <textarea className='aboutCourse' name="" id="" cols="30" rows="10"></textarea>
              </div>
              <br /><br />

              <div className="carded">
                  <h5>General Info</h5>
                
                  <div className="flexly">
                    <div>
                      <p>Course Duration</p>
                      <input type="text" className='coursePrice' />
                    </div>
                    <div>
                      <p>Some students finish in</p>
                      <input type="text" className='coursePrice' />
                    </div>
                  </div>
                  
                  <br />
                  <p>Classes Start</p>
                  <input type="text" className='coursePrice2'/>
                  <br /><br />
              </div>
              <br /><br />

              <div className="carded">
                  <h5>Pricing</h5>
                  <div className="flexly">
                    <div>
                      <p>Price</p>
                      <input type="text" className='coursePrice' />
                    </div>
                    <div>
                      <p>Monthly Price</p>
                      <input type="text" className='coursePrice' />
                    </div>
                  </div>
                  
                  <div className='flexly'>
                    <input type="checkbox" name="" id="" className='checkboxx' />
                    <p>Include price discount</p>
                  </div>

                  <p>Discounted Price</p>
                  <input type="text" className='coursePrice2'/>

              
              </div>
              <br /><br />
              <div className="carded">
                  <h5>Links</h5>
                
                  <p>Cover Image Link</p>
                  <input type="text" className='coursePrice'/>
                  <br />
                  <p>Buy Button Link</p>
                  <input type="text" className='coursePrice'/>
                  <br /><br />
              </div>

              <br /><br />
              <div className="carded">
                  <h5>What you will learn</h5>
                
                  <p>Type content and click add</p>
                  <div className="flexly">
                    <input type="text" className='wuL'/>
                    <button className='add'>ADD</button>
                  </div>
                  <br /><br />
                  
              </div>
              <br /><br />

              <div className="carded">
                  <h5>Course Units</h5>
                
                  <p>Type content and click add</p>
                  <div className="flexly">
                    <input type="text" className='courseUnit' />
                    <input type="text" className='courseUnit2' />
                    <input type="text" className='courseUnit2' />
                    <input type="text" className='courseUnit2' />
                    <button className='add'>ADD</button>
                  </div>
                  <br /><br />
              </div>


          </div>
       
        </div>   

    </div>
  )
}

export default AddCourse
import React, { useRef, useState } from 'react'
import '../styles/contact.css'
import phone from '../img/phone.png'
import mail from '../img/mail.png'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contact() {


    const [emailStatus, setEmailStatus] = useState("OK")
    const form = useRef()
    
    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_4j4j1bq','template_68vbvbl',form.current, 'z-FvprnCLBoDzTyJl')
            .then((result) =>{
                setEmailStatus(result.text)
                setEmailStatus("")
                console.log(result.text);
            }, (error) =>{
                setEmailStatus("Error")
                console.log(error.text);
            })
    }   

    const notify = () => {
        if(emailStatus === "OK"){
            toast.success("Thank you for submitting.");
        }   
        else if(emailStatus === "Error"){
            toast.error("Error occured, please try again")
        }
    }

  return (
    <div className='contact' id='contact'>
        <div className="contact-info">
            <h3>Contact Us</h3>
            <ToastContainer />
            <h1>Contact Us to Get more <span>info</span>  About our Courses</h1>
            <p>Our dedicated team is ready to assist you and guide you towards the right course that matches your interests and goals. Get in touch with us today to take the next step in your learning journey.</p>
        </div>
        <div className="contact-form">
            <form action="" ref={form} onSubmit={sendEmail}>
                <h3>Fill the Form</h3>
                <input type="text" name="from_name" id="" placeholder='Your Name'/>
                <div className="flex-contact">
                    <input type="email" name="user_email" id="" placeholder='Email Address' className='fc1' />
                    <input type="number" name="user_number" id=""  className='fc2' placeholder='Phone'/>
                </div>
                <textarea class="" name="message" rows="6" placeholder='Message' ></textarea>
                <button type='submit' onClick={notify}>Submit</button>
            </form>
        </div>

        <div className="quick-contacts">
            <div>
                <img src={phone} alt="" />
                <p>07598 327012</p>
            </div>

            <div>
                <img src={mail} alt="" />
                <p>info@weproe.co.uk</p>
            </div>
        </div>


    </div>
  )
}

export default Contact
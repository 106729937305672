import React from 'react'
import '../styles/footer.css' 
import '../styles/style.css'
import logo from '../img/complex_logo.png' 
import call from '../img/call.png'
import email from '../img/email.png'
import location from '../img/location.png' 
import { HashLink } from 'react-router-hash-link' 
import facebook from '../img/socials/facebook.png' 
import instagram from '../img/socials/instagram.png' 
import twitter from '../img/socials/twitter.png' 
import linkedin from '../img/socials/linkedin.png' 

function Footer() {

    const scrollToTop = () =>{
        window.scrollTo(0,0)
    } 

  return (
    <div className='footer'>
        <div className="grid-footer">
            <div className="sec1">
                <img src={logo} alt="" />
                <div className="privacy">
                    <p>WEPROE registered as Wellingbororough Professional Education company number <span className="bold">14725958</span></p>
                    <p>Registered Office Address 94 Fitzhugh Rise <br /> Wellingborough,  United Kingdom, NN8 6BU </p>
                    <div className='underlined'>
                        <HashLink className='generalLink' to={"privacy-protection"} onClick={scrollToTop}><p>Privacy and Protection</p></HashLink>
                        <HashLink className='generalLink' to={"terms-of-use"} onClick={scrollToTop}><p>Terms and Conditions</p></HashLink> 
                    </div>  
                </div>
               
            </div>
            
            <div className="sec2">
                <p className='sec-p' >Links</p>
                <ul className='sec-ul'>
                    <HashLink to={"/"} className='generalLink white'><li>Home</li></HashLink>
                    <HashLink to={"/#courses"} className='generalLink white'><li>Courses</li></HashLink>
                    <HashLink to={"/#about"} className='generalLink white'><li>About Us</li></HashLink>
                    <HashLink to={"/#blog"} className='generalLink white'><li>Blog</li></HashLink>
                    <HashLink to={"/#contact"} className='generalLink white'><li>Contact</li></HashLink>
                </ul>
            </div>

            <div className="sec3">
                <p className='sec-p' >Popular Courses </p>
                <ul className='sec-ul'>
                    <HashLink to={"/courses/accounting-diploma"} className='generaLink white' onClick={scrollToTop}><li>Accounting Diploma</li></HashLink> 
                    <HashLink to={"/courses/acca"} className='generaLink white' onClick={scrollToTop}><li>ACCA Qualification</li></HashLink> 
                    <HashLink to={"/courses/healthcare-level2"} className='generaLink white' onClick={scrollToTop}><li>Level 2 Diploma in Health and Social Care</li></HashLink> 
                    <HashLink to={"/courses/healthcare-level3"} className='generaLink white' onClick={scrollToTop}><li>Level 3 Diploma in Health and Social Care</li></HashLink>   
                </ul>
            </div>

            <div className="sec4">
                <p className='sec-p contact-p'>Contact </p>
                <div className="flex-div">
                    <img src={call} alt="" />
                    <p>07598 327012</p>
                </div>
               
                <div className="flex-div">
                    <img src={email} alt="" />
                    <p>info@weproe.co.uk</p>
                </div>

                <div className="flex-div start-align">
                    <img src={location} alt="" />
                    <p>1-2 Grafton Court,Kettering Parkway, <br />
                         Kettering Venture Park, <br />
                         Kettering <br />
                        NN15 6XR, United Kingdom
                    </p>
                </div>
                
            </div>

        </div>
        <div className="copyright">
            <div className="social-links">
                <a href="https://www.facebook.com/weproeuk?mibextid=LQQJ4d" target='_blank'><img src={facebook} alt="" className='social-img' /></a>
                <a href="https://www.instagram.com/weproe.co.uk?utm_source=qr" target='_blank'><img src={instagram} alt="" className='social-img' /></a>
                <a href="https://www.linkedin.com/company/weproe/" target='_blank'><img src={linkedin} alt="" className='social-img' /></a>
                <a href=""><img src={twitter} alt="" className='social-img' /></a>
            </div>
            <p>Copyright 2024. All rights reserved</p> 
        </div>

    </div>
  )
}

export default Footer
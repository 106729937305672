import React from 'react'
import '../styles/about.css'
import idea from '../img/lightbulb.png' 
import teachers from '../img/teacher.png'
import courses from '../img/online-education.png'
import cert from '../img/certificate (1).png' 
import about_image from '../img/front-view-male-student-green-checkered-shirt-wearing-black-backpack-holding-files-blue-wall-PhotoRoom.png-PhotoRoom.png' 
import { HashLink } from 'react-router-hash-link'

function About() {
  return (
    <div className='about' id='about'>
      <div className='about-cards'>
        <div className="card">
            <img src={idea} alt="" />
            <div>
                <h3>Learn Skill</h3>
                <p>Acquire New Expertise</p>
            </div>
        </div>

        <div className="card">
            <img src={teachers} alt="" />
            <div>
                <h3>Expert Teachers</h3>
                <p>Industry-Leading Experts </p>
            </div>
        </div>

        <div className="card">
            <img src={courses} alt="" />
            <div>
                <h3>Self Paced</h3>
                <p> Study flexibly</p>
            </div>
        </div>

        <div className="card">
            <img src={cert} alt="" />
            <div>
                <h3>Certificates</h3>
                <p>Recognized Certifications </p>
            </div>
        </div>
      </div>

      <div className="about-main">
        <div className="about-text">
             <h3>About Us</h3>
             <h1>Welcome to WEPROE</h1>
             <p>At WEPROE, we are passionate about fostering a love for learning and empowering individuals to reach their highest potential. Our mission is to provide a dynamic and accessible online learning environment where knowledge knows no boundaries.</p>
             <br /> 
             <p>With a curated selection of courses taught by industry experts, we aim to inspire and equip learners of all backgrounds to acquire new skills, explore diverse subjects, and embrace personal growth. We believe that education should be engaging, interactive, and tailored to individual needs. Join our community of lifelong learners and embark on a transformative educational experience that will shape your future.</p>
             <HashLink to="/#courses" className='generalLink'><button>Explore </button></HashLink>
        </div>
        <div className="about-image">
             <img src={about_image} alt="" />
        </div>
      </div>

    </div>
  )
}

export default About
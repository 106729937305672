import React from 'react'
import '../styles/course1.css'
import '../styles/course1_2.css' 
import Nav from './Nav'
import Footer from './Footer'
import star from '../img/star.png'
import thumb from '../img/like.png'
import accounting from '../img/accounting.jpg'
import check from '../img/checked.png'
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import '../styles/fancy-example.css';


function HCLv4() {
  return (
    <div>
        <Nav/>
        
        <div className="course-hero">
            <div className='course-head'>
                <h1>Level 4 Diploma in Health and Social Care (RQF) </h1>
                <p>The Level 4 Health and Social Care Diploma provides learners with the knowledge and skills required to work in health and social care in a supervisory position.</p>
                <button>Apply Now</button>
            </div>

            <div className="sum-card">
                <h3>Course</h3>
                <p>Gain insight into a topic and learn the fundamentals</p>

                 <div className="hor-gray"></div>       

                <div className="flex-course">
                    <div>
                        <h3>Reg Deadline</h3>
                        <p>Coming Soon</p>
                    </div>  
                    <div className='vert-gray'></div>
                    <div>
                        <h3>Classes Start</h3>
                        <p>Coming Soon</p>
                    </div>
                </div>
                
                <h3>Beginner</h3>
                <p>Recommended Experience</p>

                <h3>Interactive Learning</h3>
                <p>Interact with the course tutors live</p>

                <br />
                <div className="hor-gray"></div>       
                <div className="flex-course2">
                    <p>12 months | 20-30hrs/week</p>
                    
                    <a href="">View course modules</a>
                </div>

            </div>
        </div>

        <div className="about-course">
            <img src="https://images.pexels.com/photos/4021779/pexels-photo-4021779.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
            <div>
                <h1>About this course</h1>
                <p>The Level 4 Diploma in Health and Social Care (RQF) is an advanced qualification designed for individuals seeking to enhance their knowledge and skills in the field of health and social care. This diploma builds upon the foundational concepts covered in previous levels and delves deeper into key areas of practice. It provides learners with the opportunity to develop a comprehensive understanding of the complex challenges and responsibilities faced by professionals in this sector.</p>
                <p>The Level 4 Diploma in Health and Social Care (RQF) is ideal for individuals who aspire to take on leadership roles within the health and social care sector or advance their careers in areas such as policy development, service management, or quality assurance. By completing this diploma, graduates will possess the knowledge, skills, and confidence to make informed decisions, drive positive change, and contribute to the delivery of high-quality care in diverse settings.</p>
              
            </div>
        </div>

        <div className="learning-outcomes">
            <h2>What you'll learn</h2>
            <div className='grid-2'>
                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Leadership and Management in Health and Social Care: This concept focuses on developing leadership skills and understanding effective management practices within the health and social care sector. Learners will explore different leadership styles, team dynamics, and strategies for promoting collaboration and achieving organizational goals.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Strategic Planning and Policy Development: This concept delves into the process of strategic planning and policy development within health and social care settings. Students will learn how to analyze current trends and challenges, identify strategic priorities, and develop policies that align with organizational objectives and regulatory requirements.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Quality Improvement and Assurance: Quality improvement and assurance are crucial in ensuring the delivery of high-quality care. This concept covers principles and methodologies for monitoring, evaluating, and improving the quality and safety of services. Learners will explore techniques such as audits, risk assessments, and implementing quality improvement initiatives.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Ethical and Legal Considerations: This concept provides a comprehensive understanding of the ethical and legal frameworks that underpin health and social care practice. Students will explore ethical dilemmas, decision-making processes, and the importance of upholding confidentiality, consent, and privacy. They will also become familiar with relevant legislation and regulations governing the sector.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Advanced Communication and Interpersonal Skills: Effective communication is vital in health and social care settings. This concept focuses on enhancing communication and interpersonal skills at an advanced level. Learners will explore techniques for building rapport, resolving conflicts, and effectively engaging with individuals, families, and multidisciplinary teams.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Research and Evidence-Based Practice: This concept emphasizes the importance of research and evidence-based practice in health and social care. Students will learn how to critically evaluate research findings, apply evidence to inform decision-making, and contribute to the advancement of knowledge within the field. They will also gain skills in conducting research and evaluating its impact on practice.</p>
                </div>

                
            </div>
        </div>    

        <div className="outline">
            <h2>Course Outline</h2>
            <div className="accordion">
            <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 1-4: Leadership and Management in Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Introduction to leadership styles and theories</li>
                        <li>Effective team management and collaboration</li>
                        <li>Strategic planning and goal setting in health and social care</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 5-8:  Quality Improvement and Assurance
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding quality standards and benchmarks</li>
                        <li>Monitoring and evaluating service quality</li>
                        <li>Implementing quality improvement initiatives</li>
                 </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 9-12: Ethical and Legal Considerations in Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Ethical frameworks and decision-making processes</li>
                        <li>Confidentiality, consent, and privacy</li>
                        <li>Relevant legislation and regulatory requirements</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 13-16: Communication and Interpersonal Skills</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Advanced communication techniques in health and social care</li>
                        <li>Resolving conflicts and managing challenging interactions</li>
                        <li>Effective engagement with individuals, families, and teams</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 17-20: Strategic Planning and Policy Development</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Principles of person-centered care</li>
                        <li>Assessing individual needs and preferences</li>
                        <li>Developing and implementing care plans</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 21-24: Advanced Health and Social Care Research</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding research methodologies and study design</li>
                        <li>Ethical considerations in research</li>
                        <li>Critically evaluating research findings and applying evidence to practice</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 25-28: Managing Change in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding the process of change management</li>
                        <li>Overcoming resistance to change</li>
                        <li>Implementing and evaluating change initiatives</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 29-32:  Health Promotion and Public Health</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Strategies for promoting health and preventing illness</li>
                        <li>Assessing community health needs</li>
                        <li>Developing and implementing public health initiatives</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 33-36: Safeguarding and Protecting Vulnerable Individuals</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Recognizing signs of abuse and neglect</li>
                        <li>Reporting procedures and legal obligations</li>
                        <li>Supporting the well-being of vulnerable individuals</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 37-40: Mental Health and Well-being</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding mental health conditions and their impact</li>
                        <li>Support strategies and interventions</li>
                        <li>Promoting positive mental health and well-being</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 41-44: Managing Resources and Budgets in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Effective resource allocation and budgeting</li>
                        <li> Financial management and accountability</li>
                        <li>Optimizing resource utilization for improved service delivery</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 45-48: Professional Development and Reflective Practice</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Continuous professional development in health and social care</li>
                        <li>Reflective practice and self-assessment</li>
                        <li>Creating a personal development plan</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>                

        </div>   

        <div className="cost">
            <h1>Cost</h1>

            <div className='grid-cost'>
                <div className="white-card">
                    <h4>Monthly</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>30</h2>
                        <p>per month</p>
                    </div>
                    <p>If you choose this plan, you will pay the same amount in tuition every month.</p>
                </div>

                <div className="blue-card">
                    <h4>Upfront</h4>
                    <div className='hor-gray'></div>
                    <div className="blue-price">
                        <p>$</p>
                        <h2>280</h2>
                    </div>
                    <p>If you pay in full before you start, you get 20% off the tuition fee with no additional costs,and nothing to pay when you’re done.</p>
                </div>

                <div className="white-card">
                    <h4>Quartely</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>80</h2>
                        <p>per quarter</p>
                    </div>
                    <p>Pay upfront and save an extra 11% when you choose this payment plan.</p>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default HCLv4
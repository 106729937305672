import React from 'react'
import Nav from './Nav.jsx'
import Footer from './Footer.jsx'
import '../styles/singleblog.css'
import accounting from '../img/accounting.jpg'

function Privacy() {
  return (
    <div className='singleBlog'>
        <Nav/>
        
        <div className="blogItem">
            <h1 className="blog-heading">Privacy and Protection</h1>

            
            <h2>Privacy Policy</h2>
            <p>At Wellingborough Professional Education (WEPROE), we are committed to protecting the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services. By accessing our website or using our services, you agree to the terms and practices described in this policy.</p>

            <h2>Information Collection</h2>
            <p>We may collect personal information, such as your name, email address, and contact details, when you voluntarily provide it to us through our contact forms or when you interact with our website. </p>
        
            <h2>Information Use</h2>
            <p>We use the collected information to respond to your inquiries, provide you with requested information about our courses, personalize your experience on our website, and improve our services. We may also use your information to send you relevant updates, promotional materials, or newsletters, but you will always have the option to opt out of receiving such communications.</p>

            <h2>Information Sharing</h2>
            <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our website and delivering our services. We may also disclose your information when required by law or to protect our rights, safety, or the rights and safety of others.</p>
            
            <h2>Data Security</h2>
            <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security of your information.</p>
            
            <h2>Updates to the Privacy Policy:</h2>
            <p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the revised policy on our website. It is recommended that you review this policy periodically to stay informed about how we collect, use, and protect your information.</p>
            
            <h2>Contact Us</h2>
            <p>If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal information, please contact us through the provided contact information on our website.</p>


        </div>

        

        <Footer/>
    </div>
  )
}

export default Privacy
import React from 'react'
import '../styles/blog.css'
import BlogCard from './BlogCard'
import blogs from '../data/blogs'

function Blog() {

  const blogItem = blogs.map(item=>{
    return <BlogCard
          key={item.blogId}
          title={item.title}
          teaser={item.teaser}
          link={item.link}
          imgUrl={item.img}

    />
  })

  return (
    <div className='blog' id='blog'>
        <h3>Directly from the blog</h3>
        <h1>Latest Blogs</h1>

        <div className="blogs-section">
           {blogItem}
        </div>

    </div>
  )
}

export default Blog
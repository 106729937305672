import React from 'react'
import '../styles/course1.css'
import '../styles/course1_2.css' 
import Nav from './Nav'
import Footer from './Footer'
import star from '../img/star.png'
import thumb from '../img/like.png'
import accounting from '../img/accounting.jpg'
import check from '../img/checked.png'
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import '../styles/fancy-example.css';


function AccountingDiploma() {
  return (
    <div>
        <Nav/>
        
        <div className="course-hero">
            <div className='course-head'>
                <h1>Accounting Diploma: Fundamentals of <br /> Accounting Principles</h1>
                <p>The Diploma in Accounting and Finance is a qualification in its own right, designed for students with little or no background in accounting and finance. It would also be an ideal programme if you have some background in accounting and finance and would like to extend your knowledge and understanding in areas of special interest, or if you require a greater depth of knowledge before pursuing postgraduate study.</p>
                <button>
                    <a href="#buy" className='buy-link'>Buy Now</a>
                </button>

            </div>

            <div className="sum-card">
                <h3>Course</h3>
                <p>Gain insight into a topic and learn the fundamentals</p>

                 <div className="hor-gray"></div>       

                <div className="flex-course">
                    <div>
                        <h3>Reg Deadline</h3>
                        <p>Coming Soon</p>
                    </div>  
                    <div className='vert-gray'></div>
                    <div>
                        <h3>Classes Start</h3>
                        <p>Coming Soon</p>
                    </div>
                </div>
                
                <h3>Beginner</h3>
                <p>Recommended Experience</p>

                <h3>Interactive Learning</h3>
                <p>Interact with the course tutors live</p>

                <br />
                
            </div>
        </div>

        <div className="about-course">
            <img src="https://images.pexels.com/photos/53621/calculator-calculation-insurance-finance-53621.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            <div>
                <h1>About this course</h1>
                <p>It is a flexible programme, providing a solid training and foundation in accounting and finance, including mathematical skills for more advanced master’s elective courses. You will be able to both specialise in accounting and finance, and select from a wide range of courses available throughout the School.</p>
                <p>This programme is suitable for students with undergraduate degrees in arts, economics, law, business, science and engineering, as well as those with some background in accounting and finance. There is a clear route from the Diploma towards master's level study within the Department, subject to satisfactory performance.</p>
                <p>The programme is housed within the Department of Accounting, which is widely recognised throughout Europe for its excellence in accounting and financial management research and education, as well as for its leading role in public policy issues. The interface between accounting and managers, organisations, institutions, public policy and regulatory bodies is a particular teaching and research strength of the Department.</p>
            </div>
        </div>

        <div className="learning-outcomes">
            <h2>What you'll learn</h2>
            <div className='grid-2'>
                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Financial Accounting: Learn the principles and practices of recording, classifying, and summarizing financial transactions to prepare accurate and reliable financial statements, such as balance sheets, income statements, and cash flow statements.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Managerial Accounting: Understand how to use accounting information to support internal decision-making processes within organizations. Topics include cost analysis, budgeting, performance measurement, and strategic planning.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Auditing: Gain knowledge of auditing principles and techniques used to assess the reliability and integrity of financial statements. Explore auditing standards, procedures, and ethical considerations in conducting audits of organizations.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Taxation: Explore the fundamentals of tax laws and regulations, including the preparation and filing of tax returns for individuals and businesses. Topics cover income tax, sales tax, payroll tax, and tax planning strategies.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Financial Analysis: Develop skills in analyzing financial statements to evaluate the financial health and performance of organizations. Learn techniques such as ratio analysis, trend analysis, and cash flow analysis to assess profitability, liquidity, and solvency.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Ethics and Professionalism: Understand the ethical responsibilities and professional standards that govern the accounting profession. Explore ethical dilemmas, conflicts of interest, and the importance of maintaining integrity, objectivity, and confidentiality in accounting practices.</p>
                </div>

                
            </div>
        </div>    

        <div className="outline">
            <h2>Course Outline</h2>
            <div className="accordion">
            <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 1-3: Introduction to Accounting Principles
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Overview of basic accounting concepts and principles</li>
                        <li>Introduction to double-entry bookkeeping</li>
                        <li>Recording transactions in journals and ledgers</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Week 4-6: Financial Statements and Analysis
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Preparation of financial statements (income statement, balance sheet, cash flow statement)</li>
                        <li>Understanding and analyzing financial statements</li>
                        <li>Ratio analysis and interpretation</li>
                 </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 7-9: Cost Accounting
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Introduction to cost accounting concepts</li>
                        <li>Job costing and process costing</li>
                        <li>Cost-volume-profit analysis and breakeven analysis</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 10-12: Budgeting and Forecasting</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Importance of budgeting in organizations</li>
                        <li>Preparation of operating budgets and cash budgets</li>
                        <li>Variance analysis and performance evaluation</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 13-15: Taxation</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Overview of tax laws and regulations</li>
                        <li>Individual income tax calculation and filing</li>
                        <li>Introduction to corporate taxation</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 16-18: Auditing Principles</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Introduction to auditing principles and techniques</li>
                        <li>Internal control assessment</li>
                        <li>Audit planning and procedures</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 19-21: Managerial Accounting</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Cost behavior and estimation</li>
                        <li>Decision making using relevant costs and revenues</li>
                        <li>Capital budgeting and investment analysis</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 22-24: Financial Management</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Time value of money concepts</li>
                        <li>Risk and return analysis</li>
                        <li>Capital structure and financing decisions</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
        </div>                

        </div>   

        <div className="cost" id='buy'>
            <h1>Cost</h1>

            <div className='grid-cost'>
                <div className="white-card">
                    <a href="" className='buy-link'>
                       <h4>Monthly</h4>
                       <div className='hor-gray'></div>
                       <br />
                       <div className="price">
                           <p></p>
                           <h2></h2>
                           <p>Enquire Price Now</p>
                       </div>
                       <p>If you choose this plan, you will pay the same amount in tuition every month.</p>
                    </a>
                </div>

                <div className="blue-card">
                    <a href="" className='buy-link'>
                       <h4>Upfront</h4>
                       <div className='hor-gray'></div>
                       <br />
                       <div className="blue-price">
                           <p>Enquire Price Now</p>
                           <h2></h2>
                       </div>
                       <p>If you pay in full before you start, you get 20% off the tuition fee with no additional costs,and nothing to pay when you’re done.</p>
                    </a>
                </div>

                
            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default AccountingDiploma
export default [
    {
        courseId: 13,
        courseName: "Adult Social Care Courses",
        imgUrl:"https://images.pexels.com/photos/3823497/pexels-photo-3823497.jpeg?auto=compress&cs=tinysrgb&w=600",
        description: "Gain regulated qualifications in Health and Social Care, Mental Health, and more. Start or enhance a rewarding career helping adults in need.",
        duration: "4 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/adults-social-care-courses"
    },
    {
        courseId: 12,
        courseName: "Children Social Care Courses",
        imgUrl:"https://images.pexels.com/photos/8535230/pexels-photo-8535230.jpeg?auto=compress&cs=tinysrgb&w=600",
        description: "Gain regulated qualifications in Child Social Care, Mental Health, and more. Start or enhance a rewarding career helping children in need.",
        duration: "2 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/2-children-s-social-care-courses"
    },
    {
        courseId: 11,
        courseName: "All Health and Social Care Courses",
        imgUrl:"https://images.pexels.com/photos/4021779/pexels-photo-4021779.jpeg?auto=compress&cs=tinysrgb&w=600",
        description: "Explore a comprehensive range of regulated Health and Social Care courses. Start or enhance your career in this rewarding field.",
        duration: "12 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/all-health-and-social-care-courses"
    },
    {
        courseId: 10,
        courseName: "NVQ Level 3 Courses",
        imgUrl:"https://images.pexels.com/photos/7551622/pexels-photo-7551622.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
        description: "Gain regulated NVQ Level 3 courses for specialised training and certification in various fields.",
        duration: "4 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/nvq-level-3-courses"
    },
    {
        courseId: 9,
        courseName: "Registered Manager Courses ",
        imgUrl:"https://images.pexels.com/photos/7654428/pexels-photo-7654428.jpeg?auto=compress&cs=tinysrgb&w=600",
        description: "Gain regulated qualifications in social care management with our Registered Manager Courses. ",
        duration: "3 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/registered-manager-courses-social-care"
    },
    {
        courseId: 8,
        courseName: "Accounting Courses",
        imgUrl:"https://images.pexels.com/photos/53621/calculator-calculation-insurance-finance-53621.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        description: "Explore a variety of accounting courses tailored to suit your career goals and enhance your skills in finance and business management.",
        duration: "2 Courses",
        courseLink:"https://enroll.weproe.co.uk/collections/accounting-courses"
    }
    
    ]
import React, { useRef } from 'react'
import '../styles/nav.css'
import logo from '../img/logo.png'
import login_user from '../img/user.png'
import menu from '../img/list (1).png' 
import { Link } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link'

function Nav() {

  const navRef = useRef()

  const scrollToTop = () =>{
    window.scrollTo(0,0)
} 

  function toggleNav(){
    navRef.current.classList.toggle("nav-visibility")
    console.log("Clicked")
  }

  function hideNav(){
    
  }
  
  return (
    <div className='navBar'>
        <HashLink to="/"><img src={logo} alt="" className="logo" /></HashLink>
        <div className="navLinks">
            <ul>
                <HashLink to="/" className='hashLink'>Home</HashLink>
                <HashLink to="/#about" className='hashLink'>About</HashLink>
                <HashLink to="/#courses" className='hashLink'>Courses</HashLink>
                <HashLink to="/#blog" className='hashLink'>Blog</HashLink>
                <HashLink to="/#contact" className='hashLink'>Contact</HashLink>
            </ul>

            <HashLink className='signUpLink' to={"signup"} onClick={scrollToTop}>
            <div className="signIn">
                <img src={login_user} alt="" />
                <p>SIGN IN</p>
            </div>
            </HashLink> 

            <img src={menu} onClick={toggleNav} className='menu' alt="" />

           
        </div>
        <div className="mobile-nav" ref={navRef}>
            
                <ul className='mobile-ul'>
                  <HashLink to="/" className='mobileHashLink' onClick={hideNav}>Home</HashLink>
                  <HashLink to="/#about" className='mobileHashLink' onClick={hideNav}>About</HashLink>
                  <HashLink to="/#courses" className='mobileHashLink' onClick={hideNav}>Courses</HashLink>
                  <HashLink to="/#blog" className='mobileHashLink' onClick={hideNav}>Blog</HashLink>
                  <HashLink to="/#contact" className='mobileHashLink' onClick={hideNav}>Contact</HashLink>
              </ul>

              <HashLink className='signUpLink' to={"signup"} onClick={scrollToTop}>
              <div className="mobileSignIn">
                <img src={login_user} alt="" />
                <p>SIGN IN</p> 
            </div>
            </HashLink>

            </div>
    </div>
  )
}

export default Nav
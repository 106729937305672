import React from 'react'
import '../styles/faculty.css'
import Nav from './Nav'
import CourseCard from './CourseCard'
import course from '../data/course'
import healthcare from '../data/healthcare'
import Footer from './Footer'


function HealthCare() {

    const courseItem = healthcare.map(item=>{
        return    <CourseCard
                      key={item.courseId}
                      id = {item.courseId}
                      name = {item.courseName}
                      imgUrl = {item.imgUrl}
                      desc = {item.description}
                      duration = {item.duration}
                      courseLink={item.courseLink}
                      />
               
      })
     

  return (
    <div>
        <Nav/>
        <div className="faculty-head" style={{backgroundImage:'url(https://images.pexels.com/photos/7551622/pexels-photo-7551622.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load)'}}>
            <h1>Health and Social Care</h1>
            <p>Health and social care courses equip you with essential skills to provide compassionate, effective support in healthcare settings.</p>
        </div>

        <div className="desc-section">
            <p>
            Welcome to <span style={{fontWeight:"600"}}>weproe's</span>  Healthcare Academy, where you can earn qualifications to launch or advance your career. Healthcare professionals play a crucial role in our society, providing essential services that enhance quality of life.
            </p>

            <p>
            Healthcare professions—from nurses and midwives to counselors and social care specialists—offer opportunities to make a profound impact. At <span style={{fontWeight:"600"}}>weproe</span>, we offer regulated and RQF-accredited courses in specialized healthcare fields. These qualifications are widely recognized and will support your career journey, whether you're entering the sector or enhancing your skills.
            </p>

            <p>
            Our programs feature flexible online learning combined with practical experiences, ensuring you gain valuable hands-on skills. Join us today and pursue a meaningful and fulfilling career in healthcare!
            </p>
        </div>

        <div className="faculty-cards">
            <div className="grid-view">
                {courseItem}  
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default HealthCare
import React from "react"
import ReactDOM from "react-dom"
import './styles/style.css'
import App from "./App"

function Index(){
    return(
        <div className="main">
            <App/>
        </div>
    )
}

ReactDOM.render(<Index/>,document.getElementById("root"))


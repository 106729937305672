import React from 'react'
import Nav from './Nav.jsx'
import Footer from './Footer.jsx'
import '../styles/singleblog.css'
import accounting from '../img/accounting.jpg'

function Privacy() {
  return (
    <div className='singleBlog'>
        <Nav/>
        
        <div className="blogItem">
            <h1 className="blog-heading">Terms and Conditions</h1>
            <p>Please read these terms and conditions ("Terms") carefully before using our website. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our website.</p>

            
            <h2>Intellectual Property</h2>
            <p>All content on this website, including text, graphics, logos, images, videos, and software, is the property of [Your Website Name] and is protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works unless expressly authorized by us.</p>

            <h2>Use of Website</h2>
            <p>You may use our website for lawful purposes and in a manner that does not infringe upon the rights of others or restrict others from using or enjoying the website. You agree not to engage in any activity that could damage, disable, or impair the functioning of our website. </p>
        
            <h2>Links to Third-Party Websites</h2>
            <p>Our website may contain links to third-party websites that are not owned or controlled by us. We are not responsible for the content or privacy practices of such websites. Accessing these websites is at your own risk, and we recommend reviewing their terms and privacy policies.</p>

            <h2>Disclaimers</h2>
            <p>Our website and its content are provided on an "as is" and "as available" basis without any warranties, express or implied. We do not guarantee the accuracy, reliability, or completeness of the content. Your use of the website is at your own risk.</p>
            
            <h2>Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our website or any reliance on the content.</p>
            
            <h2>Indemnification</h2>
            <p>You agree to indemnify and hold us harmless from and against any claims, damages, liabilities, costs, or expenses arising out of your use of the website or any violation of these Terms.</p>
            
            <h2>Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising from these Terms or your use of the website shall be subject to the exclusive jurisdiction of the courts in Wellingborough.</p>

            <h2>Modifications</h2>
            <p>We reserve the right to modify or update these Terms at any time without prior notice. By continuing to use our website after any changes, you agree to be bound by the updated Terms.</p>

            <p>Please review these Terms periodically to ensure you are aware of any updates or changes. If you have any questions or concerns about these Terms, please contact us through the provided contact information on our website.</p>
        </div>

        

        <Footer/>
    </div>
  )
}

export default Privacy
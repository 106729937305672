import React from 'react'
import '../styles/admin.css'
import '../styles/courses.css'
import { HashLink } from 'react-router-hash-link'
import AdminCourseCard from '../components/AdminCourseCard.jsx'

function Admin() {
  return (
    <div className='courses courseDiv' id='courses'>

        <div className='topNav'>
          <p>Courses</p>
          <HashLink to={"/addCourse"} style={{textDecoration: "none"}} >
            <button>Add Courses</button>
          </HashLink>
        </div>
        <div className="">
            <AdminCourseCard/>
            <AdminCourseCard/>
            <AdminCourseCard/>
            <AdminCourseCard/>

        </div>

    </div>
  )
}

export default Admin
import React from 'react'
import '../styles/course1.css'
import '../styles/course1_2.css' 
import Nav from './Nav'
import Footer from './Footer'
import star from '../img/star.png'
import thumb from '../img/like.png'
import accounting from '../img/accounting.jpg'
import check from '../img/checked.png'
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import '../styles/fancy-example.css';


function HCLv3() {
  return (
    <div>
        <Nav/>
        
        <div className="course-hero">
            <div className='course-head'>
                <h1>Level 3 Diploma in Health and Social Care (RQF) </h1>
                <p>The Level 3 (RQF) Diploma in Health and Social Care has been designed to enable learners to access Higher Education or assist with progression into the workplace.</p>
                <button>Apply Now</button>
            </div>

            <div className="sum-card">
                <h3>Course</h3>
                <p>Gain insight into a topic and learn the fundamentals</p>

                 <div className="hor-gray"></div>       

                <div className="flex-course">
                    <div>
                        <h3>Reg Deadline</h3>
                        <p>Coming Soon</p>
                    </div>  
                    <div className='vert-gray'></div>
                    <div>
                        <h3>Classes Start</h3>
                        <p>Coming Soon</p>
                    </div>
                </div>
                
                <h3>Beginner</h3>
                <p>Recommended Experience</p>

                <h3>Interactive Learning</h3>
                <p>Interact with the course tutors live</p>

                <br />
                <div className="hor-gray"></div>       
                <div className="flex-course2">
                    <p>9 months | 20-30hrs/week</p>
                    
                    <a href="">View course modules</a>
                </div>

            </div>
        </div>

        <div className="about-course">
            <img src="https://images.pexels.com/photos/7551622/pexels-photo-7551622.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" alt="" />
            <div>
                <h1>About this course</h1>
                <p>The Level 3 Diploma in Health and Social Care (RQF) is an in-depth qualification designed to provide individuals with a comprehensive understanding of the health and social care sector. This diploma builds upon the foundational knowledge gained at Level 2 and delves into more advanced topics. Students will explore subjects such as anatomy and physiology, safeguarding, supporting individuals with specific needs, and promoting health and well-being. Through a combination of theoretical learning, practical training, and work placement opportunities, learners will develop the skills and knowledge necessary to take on more responsible roles within the industry.</p>
                <p>Throughout the course, students will develop a deep understanding of person-centered care and how to support individuals with diverse needs. They will learn about the importance of effective communication, active listening, and promoting dignity and respect. Safeguarding vulnerable individuals will also be a key focus, with students gaining knowledge of recognizing signs of abuse or neglect, understanding reporting procedures, and ensuring the safety and well-being of those under their care. The Level 3 Diploma equips learners with the necessary skills to provide high-quality care and support, making a positive impact on individuals' lives within various health and social care settings.</p>
              
            </div>
        </div>

        <div className="learning-outcomes">
            <h2>What you'll learn</h2>
            <div className='grid-2'>
                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Anatomy and Physiology: A deep understanding of the human body is crucial in health and social care. Students will learn about the structure and functions of the major body systems, including the respiratory, circulatory, nervous, and musculoskeletal systems. This knowledge is essential for providing effective care, understanding the impact of health conditions, and recognizing signs of illness or injury.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Safeguarding and Protection: Safeguarding vulnerable individuals is a critical aspect of health and social care. Students will gain an understanding of the legal and ethical frameworks surrounding safeguarding and protection. They will learn to recognize signs of abuse or neglect, understand reporting procedures, and implement strategies to ensure the safety and well-being of individuals in their care.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Supporting Individuals with Specific Needs: Health and social care professionals encounter individuals with diverse needs. Students will learn about supporting individuals with specific needs, such as those with physical disabilities, sensory impairments, or mental health conditions. They will develop skills in adapting support to meet these specific needs, promoting independence, and enabling individuals to achieve their goals.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Promoting Health and Well-being: Health promotion plays a crucial role in preventing illness and maintaining well-being. Students will explore strategies for promoting health and well-being, including healthy lifestyle choices, nutrition, exercise, and mental well-being. They will understand the importance of preventive care, health education, and empowering individuals to take an active role in managing their own health.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Effective Leadership and Management: As individuals progress in their health and social care careers, leadership and management skills become essential. Students will develop an understanding of leadership styles, effective communication, and teamwork in the context of health and social care settings. They will learn about managing resources, promoting a positive work culture, and implementing quality improvement initiatives.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Ethical and Legal Considerations: Health and social care professionals must navigate complex ethical and legal issues. Students will explore ethical frameworks, confidentiality, consent, and the rights of individuals in their care. They will gain an understanding of relevant legislation, such as data protection and mental capacity, and learn to apply ethical principles to their decision-making process.</p>
                </div>

                
            </div>
        </div>    

        <div className="outline">
            <h2>Course Outline</h2>
            <div className="accordion">
            <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Weeks 1-3: Introduction to Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Overview of the health and social care sector</li>
                        <li>Ethical considerations and professional standards</li>
                        <li>Legal frameworks and regulations</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 4-6: Anatomy and Physiology 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Structure and functions of the major body systems</li>
                        <li>Common health conditions and their impact</li>
                        <li>Recognizing signs and symptoms of illness or injury</li>
                 </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 7-9: Communication and Interpersonal Skills
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Effective verbal and non-verbal communication</li>
                        <li>Active listening and empathy</li>
                        <li>Communication in challenging situations</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 10-12: Safeguarding and Protection</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Recognizing signs of abuse or neglect</li>
                        <li>Reporting procedures and legal obligations</li>
                        <li>Promoting the well-being of vulnerable individuals</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 13-15: Supporting Individuals with Specific Needs</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding diverse needs (physical, sensory, mental health)</li>
                        <li>Adapting support to meet specific needs</li>
                        <li>Promoting independence and empowerment</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 16-18: Health Promotion and Well-being</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Strategies for promoting physical and mental well-being</li>
                        <li>Healthy lifestyle choices, nutrition, and exercise</li>
                        <li>Preventive care and health education</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 19-21: Mental Health and Well-being</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Common mental health conditions and their impact</li>
                        <li>Support strategies and interventions</li>
                        <li>Promoting positive mental health</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 22-24: Equality, Diversity, and Inclusion</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Promoting equality and diversity in health and social care</li>
                        <li>Challenging discrimination and promoting inclusivity</li>
                        <li>Cultural competence and respecting individual differences</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 25-27: Leadership and Management in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Leadership styles and effective management practices</li>
                        <li>Teamwork and collaboration</li>
                        <li>Resource management and quality improvement</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 28-30: Palliative and End-of-Life Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding the needs of individuals nearing the end of life</li>
                        <li>Providing compassionate and dignified care</li>
                        <li>Supporting individuals and their families during the end-of-life process</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 31-33: Research Skills and Evidence-Based Practice</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Importance of research in health and social care</li>
                        <li>Gathering and evaluating evidence</li>
                        <li>Applying research findings to practice</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 34-36: Ethical and Legal Considerations in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Ethical frameworks and decision-making processes</li>
                        <li>Confidentiality, consent, and privacy</li>
                        <li>Relevant legislation and its implications</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>                

        </div>   

        <div className="cost">
            <h1>Cost</h1>

            <div className='grid-cost'>
                <div className="white-card">
                    <h4>Monthly</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>30</h2>
                        <p>per month</p>
                    </div>
                    <p>If you choose this plan, you will pay the same amount in tuition every month.</p>
                </div>

                <div className="blue-card">
                    <h4>Upfront</h4>
                    <div className='hor-gray'></div>
                    <div className="blue-price">
                        <p>$</p>
                        <h2>280</h2>
                    </div>
                    <p>If you pay in full before you start, you get 20% off the tuition fee with no additional costs,and nothing to pay when you’re done.</p>
                </div>

                <div className="white-card">
                    <h4>Quartely</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>80</h2>
                        <p>per quarter</p>
                    </div>
                    <p>Pay upfront and save an extra 11% when you choose this payment plan.</p>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default HCLv3
import React from 'react'
import MainPage from './components/MainPage'
import AccountingDiploma from './components/Accounting Diploma'
import ACCAFullCourse from './components/ACCAFullCourse'
import { BrowserRouter, Route, Routes, Switch, HashRouter } from 'react-router-dom'
import HCLv2 from './components/HCLv2'
import HCLv3 from './components/HCLv3'
import HCLv4 from './components/HCLv4'
import HCLv5 from './components/HCLv5'
import SingleBlog from './components/SingleBlog'
import ACCAStudyTips from './components/ACCAStudyTips'
import ACCACosts from './components/ACCACosts'
import ACCAvsACA from './components/ACCAvsACA'
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import Admin from './components/Admin'
import AddCourse from './components/AddCourse'
import HealthCare from './components/HealthCare'
import SignUp from './components/SignUp'

function App() {
  /*
    
  */
  
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path='/' exact element={<MainPage/>}/>
          <Route path='/courses/accounting-diploma' element={<AccountingDiploma/>}/>
          <Route path='/courses/healthcare-level2' element={<HCLv2/>}/>
          <Route path='/courses/healthcare-level3' element={<HCLv3/>}/>
          <Route path='/courses/healthcare-level4' element={<HCLv4/>}/>
          <Route path='/courses/healthcare-level5' element={<HCLv5/>}/>
          <Route path='blogs/acca-exam-tips' element={<ACCAStudyTips/>}/>  
          <Route path='blogs/exploring-acca-costs' element={<ACCACosts/>}/>
          <Route path='blogs/acca-vs-aca' element={<ACCAvsACA/>}/>
          <Route path='/privacy-protection' exact element={<Privacy/>}/>
          <Route path='/#/terms-of-use' exact element={<Terms/>}/>
          <Route path='/admin' exact element={<Admin/>}/>
          <Route path='/addCourse' exact element={<AddCourse/>}/>
          <Route path='/signup' exact element={<SignUp/>}/>
          <Route path='/healthCareCourses' exact element={<HealthCare/>}/>
          </Routes>
      </HashRouter>

    </div>
  )
}

export default App
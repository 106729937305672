import React from 'react'
import '../styles/course1.css'
import '../styles/course1_2.css' 
import Nav from './Nav'
import Footer from './Footer'
import star from '../img/star.png'
import thumb from '../img/like.png'
import accounting from '../img/accounting.jpg'
import check from '../img/checked.png'
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,} from 'react-accessible-accordion';
import '../styles/fancy-example.css';


function HCLv2() {
  return (
    <div>
        <Nav/>
        
        <div className="course-hero">
            <div className='course-head'>
                <h1>Level 2 Diploma in Health and Social Care (RQF) </h1>
                <p>The Level 2 Diploma in Health and Social Care, sets out the minimum requirements for a qualification which develops and confirms the competence of those who are currently working within the Health and Care Sector. This Diploma will allow learners to develop and demonstrate competence in areas of Health and Social Care. You will also develop technical skills, knowledge, and understanding relating to specified job roles.</p>
                <button>Apply Now</button>
            </div>

            <div className="sum-card">
                <h3>Course</h3>
                <p>Gain insight into a topic and learn the fundamentals</p>

                 <div className="hor-gray"></div>       

                <div className="flex-course">
                    <div>
                        <h3>Reg Deadline</h3>
                        <p>Coming Soon</p>
                    </div>  
                    <div className='vert-gray'></div>
                    <div>
                        <h3>Classes Start</h3>
                        <p>Coming Soon</p>
                    </div>
                </div>
                
                <h3>Beginner</h3>
                <p>Recommended Experience</p>

                <h3>Interactive Learning</h3>
                <p>Interact with the course tutors live</p>

                <br />
                <div className="hor-gray"></div>       
                <div className="flex-course2">
                    <p>6 months | 20-30hrs/week</p>
                    
                    <a href="">View course modules</a>
                </div>

            </div>
        </div>

        <div className="about-course">
            <img src="https://images.pexels.com/photos/339620/pexels-photo-339620.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            <div>
                <h1>About this course</h1>
                <p>The Level 2 Diploma in Health and Social Care (RQF) is an introductory course designed to provide individuals with a solid foundation in the field of health and social care. This qualification covers essential topics such as communication, health and safety, equality and inclusion, and person-centered support. Students will gain a basic understanding of the principles and practices involved in providing care to individuals in various settings. Through a combination of classroom learning and practical training, learners will develop the skills necessary to work effectively as a part of a healthcare team and provide compassionate and person-centered support to those in need.</p>
                <p>This diploma offers a comprehensive introduction to the key concepts and principles of the healthcare sector. Students will explore topics such as safeguarding and protection, understanding mental health problems, and the safe handling of medication. This course provides learners with a solid grounding in the essential knowledge and skills required to enter the health and social care industry. Through hands-on training and work placement opportunities, students will have the chance to apply their learning in real-world healthcare settings, gaining valuable practical experience and enhancing their employability.</p>
              
            </div>
        </div>

        <div className="learning-outcomes">
            <h2>What you'll learn</h2>
            <div className='grid-2'>
                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Communication Skills: Effective communication is a fundamental aspect of providing quality care. Students will learn how to communicate with individuals in a health and social care setting, including active listening, verbal and non-verbal communication, and maintaining confidentiality. They will also develop skills in recording and reporting information accurately and appropriately.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Health and Safety: Health and safety practices play a crucial role in maintaining a safe working environment for both care providers and service users. Students will learn about the importance of health and safety regulations, risk assessment, infection control, and emergency procedures. They will understand how to identify and minimize potential hazards and ensure the well-being of individuals under their care.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Equality and Inclusion: Promoting equality and inclusion is essential in health and social care. Learners will explore concepts such as diversity, discrimination, and cultural competence. They will understand the importance of respecting individual differences, promoting equal access to care, and providing inclusive support that meets the specific needs of diverse populations.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Person-Centered Support: Person-centered care focuses on tailoring support to meet the unique needs and preferences of individuals. Students will learn about the principles of person-centered support, including promoting independence, dignity, and respect. They will develop skills in assessing individual needs, creating care plans, and implementing appropriate strategies to support individuals in achieving their goals.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Safeguarding and Protection: Safeguarding vulnerable individuals is a critical responsibility in health and social care. Students will gain an understanding of safeguarding practices, recognizing signs of abuse or neglect, and reporting procedures. They will learn about the legal and ethical frameworks surrounding safeguarding and their role in protecting the well-being of individuals in their care.</p>
                </div>

                <div className="learning-outcome">
                    <img src={check} alt="" />
                    <p>Understanding Mental Health: Mental health is an important aspect of overall well-being. Students will learn about common mental health conditions, their impact on individuals, and strategies for promoting mental well-being. They will explore approaches to supporting individuals with mental health challenges, including effective communication, active listening, and signposting to appropriate resources and services.</p>
                </div>

                
            </div>
        </div>    

        <div className="outline">
            <h2>Course Outline</h2>
            <div className="accordion">
            <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 1-3: Introduction to Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Overview of the health and social care sector</li>
                        <li>Role and responsibilities of health and social care workers</li>
                        <li>Ethical considerations and professional standards</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 4-6: Communication Skills in Health and Social Care 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Effective verbal and non-verbal communication</li>
                        <li>Active listening and responding to individuals' needs</li>
                        <li>Recording and reporting information accurately</li>
                 </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Week 7-9: Health and Safety in Health and Social Care
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding health and safety regulations and legislation</li>
                        <li>Identifying and minimizing workplace hazards</li>
                        <li>Infection control measures and procedures</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 10-12: Equality and Inclusion in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Promoting equality, diversity, and inclusion</li>
                        <li>Recognizing and challenging discrimination</li>
                        <li>Cultural competence and respecting individual differences</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 13-15: Person-Centered Support in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Principles of person-centered care</li>
                        <li>Assessing individual needs and preferences</li>
                        <li>Developing and implementing care plans</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 16-18: Safeguarding and Protection in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Recognizing signs of abuse or neglect</li>
                        <li>Reporting procedures and legal obligations</li>
                        <li>Safeguarding vulnerable individuals and promoting their well-being</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 19-21: Understanding Mental Health</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Common mental health conditions and their impact</li>
                        <li>Promoting mental well-being and resilience</li>
                        <li>Supporting individuals with mental health challenges</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 22-24: Supporting Individuals with Specific Needs</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Understanding different needs, such as physical or sensory impairments</li>
                        <li>Adapting support to meet specific needs</li>
                        <li>Promoting independence and enabling individuals to achieve goals</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem><AccordionItemHeading><AccordionItemButton>Week 25-27: The Role of Communication in Health and Social Care</AccordionItemButton></AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>
                        <li>Effective communication with individuals, families, and colleagues</li>
                        <li>Communication strategies for challenging situations</li>
                        <li>Confidentiality and handling sensitive information</li>
                    </ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </div>                

        </div>   

        <div className="cost">
            <h1>Cost</h1>

            <div className='grid-cost'>
                <div className="white-card">
                    <h4>Monthly</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>30</h2>
                        <p>per month</p>
                    </div>
                    <p>If you choose this plan, you will pay the same amount in tuition every month.</p>
                </div>

                <div className="blue-card">
                    <h4>Upfront</h4>
                    <div className='hor-gray'></div>
                    <div className="blue-price">
                        <p>$</p>
                        <h2>280</h2>
                    </div>
                    <p>If you pay in full before you start, you get 20% off the tuition fee with no additional costs,and nothing to pay when you’re done.</p>
                </div>

                <div className="white-card">
                    <h4>Quartely</h4>
                    <div className='hor-gray'></div>
                    <div className="price">
                        <p>$</p>
                        <h2>80</h2>
                        <p>per quarter</p>
                    </div>
                    <p>Pay upfront and save an extra 11% when you choose this payment plan.</p>
                </div>

            </div>
        </div>

        <Footer/>
    </div>
  )
}

export default HCLv2
export default[
    {
        blogId:1,
        title: "ACCA Exam Tips: A comprehensive guide to passing your ACCA exam in the UK",
        teaser:"To excel in your ACCA exams, you must first understand the nature of these tests. ACCA, which stands for the Association of Chartered Certified Accountants, is a globally recognised professional accounting qualification",
        link:"acca-exam-tips",
        img:"https://images.pexels.com/photos/733856/pexels-photo-733856.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
        blogId:2,
        title: "Exploring the Costs: Why ACCA Exams May Seem Expensive in the UK",
        teaser:"Pursuing the prestigious ACCA (Association of Chartered Certified Accountants) qualification in the United Kingdom is a significant investment in one's professional future. However, it's not uncommon for aspiring ACCA candidates to wonder why are ACCA exams so expensive in the UK?",
        link:"exploring-acca-costs",
        img:"https://images.pexels.com/photos/1595391/pexels-photo-1595391.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
        blogId:3,
        title: "ACCA vs ACA in the UK: A Comprehensive Comparison",
        teaser:"When it comes to pursuing a career in accounting and finance in the United Kingdom, two of the most recognised and prestigious qualifications are ACCA (Association of Chartered Certified Accountants) and ACA (Associate Chartered Accountant).",
        link:"acca-vs-aca",
        img:"https://images.pexels.com/photos/1438072/pexels-photo-1438072.jpeg?auto=compress&cs=tinysrgb&w=600"
    }
]